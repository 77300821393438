/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    NewVersionPopupContainer as BaseNewVersionPopupContainer
} from 'SourceComponent/NewVersionPopup/NewVersionPopup.container';

/** @namespace Scandipwa/Component/NewVersionPopup/Container/NewVersionPopupContainer */
export class NewVersionPopupContainer extends BaseNewVersionPopupContainer {
    componentDidMount() {
        const { showPopup, goToPreviousHeaderState, device } = this.props;

        const { serviceWorker: { controller } = {} } = navigator;

        if (!controller) {
            return;
        }

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                showPopup({
                    title: __('New version available!')
                });

                if (device.isMobile) {
                    goToPreviousHeaderState();
                }
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVersionPopupContainer);
