/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import MyAccountPrivacySettings from '../component/MyAccountPrivacySettings';

export const PRIVACY_SETTINGS = 'privacy-settings';

export class MyAccountComponentPlugin {
    renderMap = originalMember => ({
        ...originalMember,
        [PRIVACY_SETTINGS]: MyAccountPrivacySettings
    });

    propTypes = originalMember => ({
        ...originalMember,
        activeTab: PropTypes.oneOfType([
            originalMember.activeTab,
            PropTypes.oneOf([PRIVACY_SETTINGS])
        ])
    });
}

// eslint-disable-next-line
export class MyAccountContainerPlugin {
    tabMap = originalMember => ({
        ...originalMember,
        [PRIVACY_SETTINGS]: {
            url: '/privacy-settings',
            name: __('Privacy Settings')
        }
    });
}

export const { renderMap, propTypes } = new MyAccountComponentPlugin();
export const { tabMap } = new MyAccountContainerPlugin();

export default {
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap: renderMap
        },
        'static-member': {
            propTypes:  propTypes
        }
    },
    'Component/MyAccountTabList/Component': {
        'static-member': {
            propTypes: propTypes
        }
    },
    'Route/MyAccount/Container': {
        'member-property': {
            tabMap: tabMap
        }
    }
};
