/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

export const UPDATE_BRAND = 'UPDATE_BRAND';

/** @namespace ShopbyBrand/Store/Brand/Action/updateBrand */
export const updateBrand = (brand) => ({
    type: UPDATE_BRAND,
    brand
});

export const SET_BRAND_LOADING = 'SET_BRAND_LOADING';

/** @namespace ShopbyBrand/Store/Brand/Action/setIsLoading */
export const setIsLoading = (isLoading) => ({
    type: SET_BRAND_LOADING,
    isLoading
});
