/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { fetchQuery } from 'Util/Request';
import PrivacyConsentCheckboxes from './PrivacyConsentCheckboxes.component';
import { privacyCheckboxAreaType } from '../../type/Privacy';
import { ALL } from '../../util/Privacy';
import PrivacyInformationQuery from '../../query/PrivacyInformation.query';

export class PrivacyConsentCheckboxesContainer extends PureComponent {
    static propTypes = {
        area: privacyCheckboxAreaType.isRequired,
        updateSelection: PropTypes.func,
    };

    static defaultProps = {
        updateSelection: () => {
        },
    };

    containerFunctions = {
        isBoxAccepted: this.isBoxAccepted.bind(this),
        setBoxAccepted: this.setBoxAccepted.bind(this),
    };

    state = {
        consents: {},
        privacyPolicy: '',
        isLoading: true,
        acceptStatus: {},
        // Set default state to true to accept policy by default as it is not needed
        // right now for the client to be displayed on FE
        // ticket WHEY-396
        isSatisfied: true,
        isDisabled: true
    };

    containerProps = () => {
        const {
            isSatisfied, acceptStatus, privacyPolicy, isDisabled,
        } = this.state;

        return ({
            isSatisfied,
            acceptStatus,
            areaConsents: this.getCurrentConsents(),
            privacyPolicy,
            isDisabled,
        });
    };

    componentDidMount() {
        this.requestPrivacyInformation();
        this.updateValidation();
        this.updateParent();
    }

    componentDidUpdate() {
        this.updateValidation();
        this.updateParent();
    }

    setBoxAccepted(code, value) {
        this.setState(({ acceptStatus }) => ({
            acceptStatus: {
                ...acceptStatus,
                [code]: value,
            },
        }));
    }

    isBoxAccepted(code) {
        const { acceptStatus } = this.state;
        const setValue = acceptStatus[code];

        if (typeof setValue !== 'undefined') {
            return setValue;
        }

        const value = this.getCurrentConsents()
            .find(({ code: item }) => item === code).isAgreed;
        this.setBoxAccepted(code, value);

        return value;
    }

    render() {
        const { isLoading } = this.state;

        return (
            <PrivacyConsentCheckboxes
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
              isLoading={ isLoading }
            />
        );
    }

    updateValidation() {
        const { isSatisfied: oldIsSatisfied } = this.state;
        const consents = this.getCurrentConsents();

        const isSatisfied = consents
            .filter(({ isRequired }) => isRequired)
            .every(({ code }) => this.isBoxAccepted(code));

        if (isSatisfied !== oldIsSatisfied) {
            // Set default state to true to accept policy by default as it is not needed
            // right now for the client to be displayed on FE
            // ticket WHEY-396
            this.setState({ isSatisfied: true });
        }
    }

    getCurrentConsents() {
        const { area } = this.props;
        const { consents } = this.state;

        if (area !== ALL) {
            return consents[area] || [];
        }

        const all = Object.values(consents)
            .reduce(
                (a, b) => [...a, ...b],
                []
            );

        // make unique
        return all.filter(
            ({ code }, i) => all.findIndex(({ code: other }) => other === code) === i
        );
    }

    updateParent() {
        const { isSatisfied, acceptStatus } = this.state;
        const { updateSelection } = this.props;

        updateSelection(
            acceptStatus,
            isSatisfied
        );
    }

    requestPrivacyInformation() {
        this.setState(
            { isLoading: true },
            () => {
                fetchQuery(PrivacyInformationQuery.getQuery())
                    .then(
                        ({ getPrivacyInformation: { consents, privacyPolicy } }) => {
                            if (consents === null) {
                                this.setState({
                                    isLoading: false,
                                    isDisabled: true,
                                });
                            } else {
                                this.setState({
                                    isLoading: false,
                                    consents,
                                    privacyPolicy,
                                });
                            }
                        },
                        e => console.error(e)
                    );
            }
        );
    }
}

export default PrivacyConsentCheckboxesContainer;
