/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { UPDATE_BRAND_CONFIG } from './BrandConfig.action';

export const initialState = {
    isLoading: true
};

/** @namespace ShopbyBrand/Store/BrandConfig/Reducer/brandConfigReducer */
export const brandConfigReducer = (state = initialState, action) => {
    const {
        type,
        data
    } = action;

    if (type === UPDATE_BRAND_CONFIG) {
        return {
            ...state,
            isLoading: false,
            ...data
        };
    }

    return state;
};

export default brandConfigReducer;
