/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import PropTypes from 'prop-types';

export const brandType = PropTypes.shape({
    attribute_code: PropTypes.string.isRequired,
    option_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    url_key: PropTypes.string.isRequired,
    logo: PropTypes.string,
    logoUrl: PropTypes.string,
    is_featured: PropTypes.bool.isRequired,
    meta_description: PropTypes.string,
    description: PropTypes.string
});

export const brandListType = PropTypes.arrayOf(brandType);
