// eslint-disable-next-line
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountNewsletterSubscriptionContainer as SourceMyAccountNewsletterSubscriptionContainer
} from 'SourceComponent/MyAccountNewsletterSubscription/MyAccountNewsletterSubscription.container';

import MyAccountQuery from 'Query/MyAccount.query';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { fetchMutation } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

/** @namespace Scandipwa/Component/MyAccountNewsletterSubscription/Container/MyAccountNewsletterSubscriptionContainer */
export class MyAccountNewsletterSubscriptionContainer extends SourceMyAccountNewsletterSubscriptionContainer {
    onCustomerSave(customer) {
        const { updateCustomer, showSuccessNotification } = this.props;
        // const { showSuccessNotification } = this.props;

        const mutation = MyAccountQuery.getUpdateInformationMutation(customer);

        this.setState({ isLoading: true });

        return fetchMutation(mutation).then(
            /** @namespace Scandipwa/Component/MyAccountNewsletterSubscription/Container/fetchMutation/then */
            ({ updateCustomerV2: { customer } }) => {
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);

                this.setState({ isLoading: false }, () => {
                    updateCustomer(customer);
                    showSuccessNotification(__('Subscription settings successfully updated'));
                });
                // this.setState({ isLoading: false });
                // showSuccessNotification(__('Subscription settings successfully updated'));
            },
            this.onError
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountNewsletterSubscriptionContainer);
