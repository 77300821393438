import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductListContainer as SourceProductListContainer
} from 'SourceComponent/ProductList/ProductList.container';

/** @namespace Scandipwa/Component/ProductList/Container/ProductListContainer */
export class ProductListContainer extends SourceProductListContainer {
    containerProps = () => ({
        currentPage: this._getPageFromUrl(),
        isShowLoading: this._isShowLoading(),
        isVisible: this._isVisible(),
        isPaginationEnabled: this._getIsPaginationEnabled(),
        pagesCount: this.state.pagesCount,
        requestPage: this.requestPage,
        // disable this property to enable infinite scroll on desktop
        isInfiniteLoaderEnabled: this._getIsInfiniteLoaderEnabled()
    });

    _getIsPaginationEnabled() {
        const { isPaginationEnabled, device } = this.props;

        if (device.isMobile) {
            return false;
        }

        return isPaginationEnabled;
    }

    _getIsInfiniteLoaderEnabled() { // disable infinite scroll on mobile
        const { isInfiniteLoaderEnabled, device } = this.props;

        // allow scroll and mobile
        if (device.isMobile) {
            return !isInfiniteLoaderEnabled;
        }

        return false;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductListContainer));
