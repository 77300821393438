/* eslint-disable */
import Breadcrumbs from 'Component/Breadcrumbs';
import DemoNotice from 'Component/DemoNotice';
import Header from 'Component/Header';
import NewVersionPopup from 'Component/NewVersionPopup';
import NotificationList from 'Component/NotificationList';

import {
    Router as BaseRouter
} from 'SourceComponent/Router/Router.component';
import {
    BEFORE_ITEMS_TYPE
} from './Router.config';

/** @namespace Scandipwa/Component/Router/Component/RouterComponent */
export class RouterComponent extends BaseRouter {
    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10
        },
        {
            component: <DemoNotice />,
            position: 15
        },
        {
            component: <Header />,
            position: 20
        },
        {
            component: <Breadcrumbs />,
            position: 30
        },
        {
            component: <NewVersionPopup />,
            position: 35
        }
    ];
}

export default RouterComponent;
