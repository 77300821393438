/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { BrandConfigDispatcher } from '../store/BrandConfig';
import { BrandListDispatcher } from '../store/BrandList';

class RouterContainerPlugin {
    mapDispatchToProps = (args, callback, instance) => {
        const [dispatch] = args;
        const original = callback.apply(instance, args);

        return {
            ...original,
            init: (options) => {
                original.init(options);
                BrandConfigDispatcher.handleData(dispatch);
                BrandListDispatcher.handleData(dispatch);
            }
        };
    };
}

const { mapDispatchToProps } = new RouterContainerPlugin();

export default {
    'Component/Router/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
