/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { ProductListQuery } from 'Query/ProductList.query';
import { Field } from 'SourceUtil/Query';

/** @namespace ShopbyBrand/Query/BrandProductList/Query/BrandProductListQuery */
export class BrandProductListQuery extends ProductListQuery {
    _getProductsField() {
        const products = new Field('brandProducts')
            .addFieldList(this._getProductFields());

        this._getProductArguments().forEach((arg) => products.addArgument(...arg));

        return products;
    }

    _getArgumentsMap() {
        const filterArgumentMap = this._getFilterArgumentMap();
        return {
            ...super._getArgumentsMap(),
            filter: {
                type: 'ProductAttributeFilterInput!',
                handler: (initialOptions = {}) => {
                    const options = {
                        ...initialOptions
                    };

                    const {
                        customFilters: { category_id } = {}
                    } = options;

                    /**
                     * Remove category ID from select, if there is a custom filter
                     * of category already selected in filtering options.
                     */
                    if (category_id) {
                        // eslint-disable-next-line fp/no-delete
                        options.categoryIds = undefined;
                    }

                    const parsedOptions = Object.entries(options).reduce(
                        (acc, [key, option]) => {
                            // if there is no value, or if the key is just not present in options object
                            if (!option || !filterArgumentMap[key]) {
                                return acc;
                            }

                            return { ...acc, ...filterArgumentMap[key](option) };
                        },
                        {}
                    );

                    return parsedOptions;
                }
            },
            brandUrl: {
                type: 'String!',
                handler: (option) => encodeURIComponent(option)
            }
        };
    }
}

export default new (BrandProductListQuery)();
