/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import React, { PureComponent } from 'react';
import Html from 'Component/Html';
import PropTypes from 'prop-types';

import Popup from 'Component/Popup';

export const PRIVACY_POLICY_POPUP_ID = 'PrivacyPolicyPopup';

export class PrivacyPolicyPopup extends PureComponent{
    static propTypes = {
        payload: PropTypes.shape({
            PrivacyPolicyPopup: PropTypes.shape({
                privacyPolicy: PropTypes.string
            })
        })
    };

    static defaultProps = {
        payload: {}
    };

    renderContent() {
        const { payload: { PrivacyPolicyPopup: { privacyPolicy = false } = {} } = {} } = this.props;

        if (!privacyPolicy) {
            return null;
        }

        return (
            <article>
                <Html content={ privacyPolicy } />
            </article>
        );
    }

    render() {
        return (
            <Popup
              id={ PRIVACY_POLICY_POPUP_ID }
              clickOutside={ false }
              mix={ { block: 'MyAccountPrivacySettingsPopup' } }
            >
                { this.renderContent() }
            </Popup>
        );
    }
}

export default PrivacyPolicyPopup;
