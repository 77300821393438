/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import TextPlaceholder from 'Component/TextPlaceholder';
import {
    CategoryItemsCount as BaseCategoryItemsCount
} from 'SourceComponent/CategoryItemsCount/CategoryItemsCount.component';

/** @namespace Component/CategoryItemsCount/Component */
export class CategoryItemsCount extends BaseCategoryItemsCount {
    render() {
        const {
            totalItems,
            isMatchingListFilter
        } = this.props;

        return (
            <p block="CategoryPage" elem="ItemsCount">
                <TextPlaceholder
                  content={ (!isMatchingListFilter
                      ? __('Products are loading...')
                      : __('%s pieces', totalItems)
                  ) }
                />
            </p>
        );
    }
}

export default CategoryItemsCount;
