/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { updateNoMatch } from 'SourceStore/NoMatch/NoMatch.action';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import { QueryDispatcher } from 'SourceUtil/Request';

import BrandQuery from '../../query/Brand.query';
import { setIsLoading, updateBrand } from './Brand.action';

/** @namespace ShopbyBrand/Store/Brand/Dispatcher/BrandDispatcher */
export class BrandDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess({ brand }, dispatch) {
        if (typeof brand === 'undefined') {
            return;
        }
        if (brand.length === 0) {
            dispatch(updateNoMatch(true));
        }
        if (brand.length === 1) {
            // The same resolver is used for fetching brand list, therefore need to access
            // the first element of a single element array
            dispatch(updateBrand(brand[0]));
        }
    }

    onError(error, dispatch) {
        dispatch(showNotification('error', __('Could not fetch brand information')));
        // eslint-disable-next-line no-console
        console.error(error);
    }

    prepareRequest(brandUrl, dispatch) {
        dispatch(setIsLoading(true));

        return BrandQuery.getQueryForBrandUrl(brandUrl);
    }
}

export default new BrandDispatcher();
