/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './GdprCookieGroupItem.style.scss';

/** @namespace GdprCookie/Component/GdprCookieGroupItem/Component/GdprCookieGroupItemComponent */
export class GdprCookieGroupItemComponent extends PureComponent {
    static propTypes = {
        isEssential: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        showPopup: PropTypes.func.isRequired,
        isChecked: PropTypes.bool.isRequired,
        handleToggleCheck: PropTypes.func.isRequired
    };

    renderHeadingAndToggle() {
        const {
            name,
            isEssential,
            isChecked,
            handleToggleCheck
        } = this.props;

        return (
            <div
              block="GdprCookieGroupItem"
              elem="HeadingAndToggle"
            >
                <h4
                  block="GdprCookieGroupItem"
                  elem="Heading"
                >
                    { name }
                </h4>
                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                <label
                  block="GdprCookieGroupItem"
                  elem="Toggle"
                >
                    <input
                      type="checkbox"
                      defaultChecked={ isEssential ? true : isChecked }
                      disabled={ isEssential }
                      onChange={ handleToggleCheck }
                    />
                    <span
                      block="GdprCookieGroupItem"
                      elem="Slider"
                      mods={ { isEssential } }
                    />
                </label>
            </div>
        );
    }

    renderDescription() {
        const { description } = this.props;

        return (
            <p
              block="GdprCookieGroupItem"
              elem="Description"
            >
                { description }
            </p>
        );
    }

    renderMoreInfo() {
        const { showPopup } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <span
              block="GdprCookieGroupItem"
              elem="MoreInfo"
              onClick={ showPopup }
            >
                { __('More information') }
            </span>
        );
    }

    render() {
        return (
            <div
              block="GdprCookieGroupItem"
              elem="Wrapper"
            >
                    { this.renderHeadingAndToggle() }
                    { this.renderDescription() }
                    { this.renderMoreInfo() }
            </div>
        );
    }
}

export default GdprCookieGroupItemComponent;
