/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import NoMatch from '@scandipwa/scandipwa/src/route/NoMatch/NoMatch.container';

import ContentWrapper from 'Component/ContentWrapper';
import { CategoryPage } from 'SourceRoute/CategoryPage/CategoryPage.component';

import BrandInfo from '../../component/BrandInfo';
import BrandProductList from '../../component/BrandProductList';

import './BrandPage.style';

/** @namespace ShopbyBrand/Route/BrandPage/Component/BrandPageComponent */
export class BrandPageComponent extends CategoryPage {
    renderCategoryDetails() {
        return this.renderBrandInfo();
    }

    renderBrandInfo() {
        const { brand, isBrandLoading } = this.props;
        return (
            <BrandInfo
              mix={ {
                  block: 'ProductActions',
                  elem: 'ShopByBrand'
              } }
              brand={ brand }
              isLoading={ isBrandLoading }
              withDescription
            />
        );
    }

    renderCategoryProductList() {
        // don't render category product list; render brand product list instead
        return this.renderBrandProductList();
    }

    renderBrandProductList() {
        const {
            selectedSort
        } = this.props;

        return (
            <div block="BrandPage" elem="ProductListWrapper">
                <BrandProductList
                  /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
                  { ...this.props }
                  sort={ selectedSort }
                />
            </div>
        );
    }

    renderContent() {
        const { device } = this.props;

        if (device.isMobile) {
            return (
                <>
                    { this.renderMiscellaneous() }
                    { this.renderFilterOverlay() }
                    <ContentWrapper
                      wrapperMix={ {
                          block: 'CategoryPage',
                          elem: 'BrandWrapper'
                      } }
                      label="Brand info"
                    >
                        { this.renderCategoryDetails() }
                    </ContentWrapper>
                    { this.renderCmsBlock() }
                    { this.renderCategoryProductList() }
                </>
            );
        }

        return (
            <>
                { this.renderFilterOverlay() }
                { this.renderCmsBlock() }
                { this.renderMiscellaneous() }
                { this.renderCategoryProductList() }
            </>
        );
    }

    render() {
        const { brand, isNoMatch, device } = this.props;

        if (
            typeof brand === 'undefined'
            || isNoMatch
        ) {
            return <NoMatch />;
        }

        const hideProducts = !this.displayProducts();

        if (device.isMobile) {
            return (
                <main block="CategoryPage">
                    <ContentWrapper
                      wrapperMix={ {
                          block: 'CategoryPage',
                          elem: 'Wrapper',
                          mods: { hideProducts }
                      } }
                      label="Category page"
                    >
                        { this.renderContent() }
                    </ContentWrapper>
                </main>
            );
        }

        return (
            <main block="CategoryPage">
                <ContentWrapper
                  wrapperMix={ {
                      block: 'CategoryPage',
                      elem: 'BrandWrapper'
                  } }
                  label="Brand info"
                >
                    { this.renderCategoryDetails() }
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ {
                      block: 'CategoryPage',
                      elem: 'Wrapper',
                      mods: { hideProducts }
                  } }
                  label="Category page"
                >
                    { this.renderContent() }
                </ContentWrapper>
            </main>
        );
    }
}

export default BrandPageComponent;
