/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { updateNoMatch } from 'SourceStore/NoMatch/NoMatch.action';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import {
    updateInfoLoadStatus,
    updateProductListInfo
} from 'SourceStore/ProductListInfo/ProductListInfo.action';
import { ProductListInfoDispatcher } from 'SourceStore/ProductListInfo/ProductListInfo.dispatcher';

import BrandProductListQuery from '../../query/BrandProductList.query';

export const NO_SUCH_ENTITY = 'graphql-no-such-entity';

/**
 * Product List Info Dispatcher
 * @class ProductListInfoDispatcher
 * @extends QueryDispatcher
 * @namespace ShopbyBrand/Store/BrandProductListInfo/Dispatcher/BrandProductListInfoDispatcher
 */
export class BrandProductListInfoDispatcher extends ProductListInfoDispatcher {
    prepareRequest(options, dispatch) {
        dispatch(updateInfoLoadStatus(true));

        return BrandProductListQuery.getQuery({
            ...options,
            requireInfo: true
        });
    }

    onSuccess({ brandProducts }, dispatch, options) {
        const {
            args: {
                filter
            }
        } = options;

        dispatch(updateProductListInfo(brandProducts, filter));
    }

    lastError = null;

    onError([error], dispatch) {
        const { extensions: { category } } = error;
        if (category === NO_SUCH_ENTITY) {
            dispatch(updateNoMatch(true));
        } else {
            dispatch(showNotification('error', 'Error fetching Product List Information'));
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }
}

export default new (BrandProductListInfoDispatcher)();
