/* eslint-disable max-lines */
/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CATEGORY } from 'Component/Header/Header.config';
import {
    LOADING_TIME
} from 'SourceRoute/CategoryPage/CategoryPage.config';
import {
    CategoryPageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceRoute/CategoryPage/CategoryPage.container';
import { updateBreadcrumbs } from 'SourceStore/Breadcrumbs/Breadcrumbs.action';
import {
    HistoryType,
    LocationType,
    MatchType
} from 'SourceType/Common';
import { debounce } from 'SourceUtil/Request';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { appendWithStoreCode } from 'Util/Url';

import { BrandDispatcher } from '../../store/Brand';
import { BrandMetaDispatcher } from '../../store/BrandMeta';
import { BrandProductListInfoDispatcher } from '../../store/BrandProductListInfo';
import { BRAND_URL } from '../../util/brand';
import BrandPage from './BrandPage.component';

/** @namespace ShopbyBrand/Route/BrandPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    brand: state.BrandReducer.brand,
    isBrandLoading: state.BrandReducer.isLoading,
    brandList: state.BrandListReducer.brandList,
    isNoMatch: state.NoMatchReducer.noMatch,
    selectedFilter: state.ProductListInfoReducer.selectedFilter
});

/** @namespace ShopbyBrand/Route/BrandPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    requestCategory: null,
    updateMetaFromCategory: null,
    requestProductListInfo: (options) => BrandProductListInfoDispatcher.handleData(dispatch, options),
    requestBrand: (brandUrl) => BrandDispatcher.handleData(dispatch, brandUrl),
    updateBreadcrumbs: (breadcrumbs) => dispatch(updateBreadcrumbs(breadcrumbs)),
    updateMetaFromBrand: (brand) => BrandMetaDispatcher.updateWithBrand(brand, dispatch),
    toggleBreadcrumbs: (state) => dispatch(toggleBreadcrumbs(state))
});

/** @namespace ShopbyBrand/Route/BrandPage/Container/BrandPageContainer */
export class BrandPageContainer extends CategoryPageContainer {
    static propTypes = {
        history: HistoryType.isRequired,
        location: LocationType.isRequired,
        match: MatchType.isRequired,
        requestBrand: PropTypes.func.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        changeNavigationState: PropTypes.func.isRequired,
        requestProductListInfo: PropTypes.func.isRequired,
        setBigOfflineNotice: PropTypes.func.isRequired,
        updateMetaFromBrand: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        updateLoadStatus: PropTypes.func.isRequired,
        updateNoMatch: PropTypes.func.isRequired,
        filters: PropTypes.objectOf(PropTypes.shape).isRequired,
        sortFields: PropTypes.shape({
            // eslint-disable-next-line react/forbid-prop-types
            options: PropTypes.array
        }).isRequired,
        isNoMatch: PropTypes.bool.isRequired,
        currentArgs: PropTypes.shape({
            filter: PropTypes.shape({
                categoryIds: PropTypes.number
            })
        }),
        selectedInfoFilter: PropTypes.shape({
            categoryIds: PropTypes.number
        }),
        isInfoLoading: PropTypes.bool.isRequired,
        isOffline: PropTypes.bool.isRequired,
        categoryIds: PropTypes.number,
        isOnlyPlaceholder: PropTypes.bool,
        isSearchPage: PropTypes.bool,
        toggleBreadcrumbs: PropTypes.func.isRequired
    };

    static defaultProps = {
        categoryIds: -1,
        isSearchPage: false,
        currentArgs: {},
        selectedInfoFilter: {}
    };

    containerProps = () => ({
        filter: this.getFilter(),
        isCurrentCategoryLoaded: this.isCurrentCategoryLoaded(),
        isMatchingListFilter: this.getIsMatchingListFilter(),
        isMatchingInfoFilter: this.getIsMatchingInfoFilter(),
        selectedSort: this.getSelectedSortFromUrl(),
        selectedFilters: this.getSelectedFiltersFromUrl(),
        isContentFiltered: this.isContentFiltered(),
        brandUrl: this.getBrandUrlPath()
    });

    componentDidMount() {
        window.scrollTo(0, 0);
        this.requestCategory();

        this.updateHeaderState();
    }

    componentDidUpdate(prevProps) {
        const {
            brand: { url_key }, isOffline
        } = this.props;

        const { brand: { url_key: prevKey } } = prevProps;

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        if (this.getIsNewBrand()) {
            this.requestCategory();
        }
        if (url_key !== prevKey) {
            this.onBrandUpdate();
        }
    }

    isBrandUrlValid() {
        const { brandList } = this.props;
        return brandList.some((brand) => brand.url_key === this.getBrandUrlPath());
    }

    onBrandUpdate() {
        const { updateMetaFromBrand, brand } = this.props;

        updateMetaFromBrand(brand);
        this.updateBreadcrumbs();
        this.updateHeaderState();
        this.updateNavigationState();
    }

    updateMeta() {
        const { updateMetaFromBrand, brand } = this.props;

        updateMetaFromBrand(brand);
    }

    updateBreadcrumbs() {
        const {
            brand: { name } = {},
            updateBreadcrumbs,
            toggleBreadcrumbs,
            device
        } = this.props;

        if (device.isMobile) {
            toggleBreadcrumbs(false);
        } else {
            updateBreadcrumbs([
                {
                    name
                },
                {
                    url: BRAND_URL,
                    name: __('Brand')
                },
                {
                    url: '/',
                    name: __('Home')
                }
            ]);
        }
    }

    updateHeaderState() {
        const {
            changeHeaderState,
            brand: {
                name
            },
            history
        } = this.props;

        const { category } = history?.location?.state || {};

        const onBackClick = category
            ? () => history.goBack()
            : () => history.push(appendWithStoreCode('/menu'));

        changeHeaderState({
            name: CATEGORY,
            title: name,
            onBackClick
        });
    }

    getIsNewBrand() {
        const { brand: { url_key } = {} } = this.props;
        return url_key !== this.getBrandUrlPath();
    }

    getProductListOptions(currentPage) {
        const customFilters = this.getSelectedFiltersFromUrl();
        const brandUrl = this.getBrandUrlPath();

        return {
            args: {
                filter: {
                    customFilters
                },
                brandUrl
            },
            currentPage
        };
    }

    getBrandUrlPath() {
        const { match: { params: { brandUrl } } } = this.props;
        return brandUrl;
    }

    requestCategory() {
        // no need to request a category in the brand page
        // request brand instead
        this.requestBrand();
    }

    getIsMatchingInfoFilter() {
        // This is to make the filters show up on the brand page
        // This doesn't affect the loader when switching between brands
        return true;
    }

    requestBrand() {
        const { requestBrand } = this.props;
        const brandUrl = this.getBrandUrlPath();
        requestBrand(brandUrl);
    }

    render() {
        const { pageSize } = this.config;
        const category = { is_anchor: true };

        return (
            <BrandPage
              /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.props }
              pageSize={ pageSize }
              { ...this.containerFunctions }
              { ...this.containerProps() }
              category={ category }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandPageContainer);
