/* eslint-disable radix */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/* eslint-disable import/prefer-default-export */
import currencyMap from 'Util/Price/Price.config';

/** @namespace Scandipwa/Util/Price/formatCurrency */
export const formatCurrency = (currency = 'USD') => currencyMap[currency];

/** @namespace Scandipwa/Util/Price/formatPrice */
export const formatPrice = (price, currency = 'USD') => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;
    return new Intl.NumberFormat(language, { style: 'currency', currency }).format(price);
};

/**
 * Calculate final price
 * @param {Number} discount discount percentage
 * @param {Number} min minimum price
 * @param {Number} reg regular price
 * @return {Number} final price
 * @namespace Scandipwa/Util/Price/calculateFinalPrice */
export const calculateFinalPrice = (discount, min, reg) => (discount ? min : reg);

/**
 * Calculate final price
 * @param {Number} price
 * @return {Number} price rounded to 2 digits
 * @namespace Scandipwa/Util/Price/roundPrice */
export const roundPrice = (price) => parseFloat(price).toFixed(2);

/** @namespace Scandipwa/Util/Price/handleMoveCurrency */
export const handleMoveCurrency = (price) => {
    if (Number.isInteger(parseInt(price.slice(-1)))) {
        if (!Number.isInteger(parseInt(price.slice(0, 1)) && price.slice(0, 2) !== ' ')) {
            return `${price.slice(0, 1)}  ${price.substring(1)}`;
        }

        return price;
    }

    return `${price.slice(-1)}  ${price.slice(0, -1)}`;
};
