import {
    MyAccountNewsletterSubscription as SourceMyAccountNewsletterSubscription
} from 'SourceComponent/MyAccountNewsletterSubscription/MyAccountNewsletterSubscription.component';

import PrivacyConsentCheckboxesContainer
    from '../../../packages/@scandipwa/gdpr/src/component/PrivacyConsentCheckboxes/PrivacyConsentCheckboxes.container';

/** @namespace Scandipwa/Component/MyAccountNewsletterSubscription/Component/MyAccountNewsletterSubscriptionComponent */
export class MyAccountNewsletterSubscriptionComponent extends SourceMyAccountNewsletterSubscription {
    renderActions() {
        return (
            <PrivacyConsentCheckboxesContainer
              area="subscription"
            >
                <button
                  type="submit"
                  block="Button"
                  mix={ { block: 'MyAccountNewsletterSubscription', elem: 'Button' } }
                >
                    { __('Save changes') }
                </button>
            </PrivacyConsentCheckboxesContainer>
        );
    }
}

export default MyAccountNewsletterSubscriptionComponent;
