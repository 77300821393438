/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

export const UPDATE_BRAND_CONFIG = 'UPDATE_BRAND_CONFIG';

/** @namespace ShopbyBrand/Store/BrandConfig/Action/updateBrandConfig */
export const updateBrandConfig = (data) => ({
    type: UPDATE_BRAND_CONFIG,
    data
});
