/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

export const shopByBrandLogoBase = '/media/aw_sbb/brand';

export const BRAND_URL = '/brand';

/** @namespace ShopbyBrand/Util/Brand/getLogoUrl */
export const getLogoUrl = (logo) => logo && `${shopByBrandLogoBase}/${logo}`;

/** @namespace ShopbyBrand/Util/Brand/formatBrand */
export const formatBrand = (brand) => brand && ({
    ...brand,
    logoUrl: getLogoUrl(brand.logo)
});

/** @namespace ShopbyBrand/Util/Brand/formatBrandList */
export const formatBrandList = (brandList) => brandList && brandList.map(
    formatBrand
);
