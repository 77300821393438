/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { formatBrand } from '../../util/brand';
import { SET_BRAND_LOADING, UPDATE_BRAND } from './Brand.action';

export const initialState = { brand: { }, isLoading: true };

/** @namespace ShopbyBrand/Store/Brand/Reducer/BrandReducer */
export const BrandReducer = (state = initialState, action) => {
    const {
        type,
        isLoading,
        brand
    } = action;

    switch (type) {
    case UPDATE_BRAND:
        return {
            ...state,
            brand: formatBrand(brand),
            isLoading: false
        };
    case SET_BRAND_LOADING:
        return {
            ...state,
            isLoading
        };
    default:
        return state;
    }
};

export default BrandReducer;
