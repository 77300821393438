/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import GdprCookieGroupPopup from '../component/GdprCookieGroupPopup';
import GdprCookiePopup from '../component/GdprCookiePopup';

/** @namespace GdprCookieGrahpQl/Plugin/Router/Component/afterItemType */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
export const AFTER_ITEMS_TYPE = (originalMember) => ([
    ...originalMember,
    {
        component: <GdprCookiePopup />,
        position: 25
    },
    {
        component: <GdprCookieGroupPopup />,
        position: 30
    }
]);

export default {
    'Scandipwa/Component/Router/Component/RouterComponent': {
        'member-property': {
            AFTER_ITEMS_TYPE
        }
    }
};
