/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateLoadStatus } from 'SourceStore/ProductList/ProductList.action';

import { BrandProductListDispatcher } from '../../store/BrandProductList';
import ModifiedProductList from '../ModifiedProductList/ModifiedProductList.container';

/** @namespace ShopbyBrand/Component/BrandProductList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    pages: state.ProductListReducer.pages,
    isOffline: state.OfflineReducer.isOffline,
    isLoading: state.ProductListReducer.isLoading,
    totalItems: state.ProductListReducer.totalItems,
    totalPages: state.ProductListReducer.totalPages
});

/** @namespace ShopbyBrand/Component/BrandProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestProductList: (options) => BrandProductListDispatcher.handleData(dispatch, options),
    updateLoadStatus: (isLoading) => dispatch(updateLoadStatus(isLoading))
});

/** @namespace ShopbyBrand/Component/BrandProductList/Container/BrandProductListContainer */
export class BrandProductListContainer extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        requestProductList: PropTypes.func.isRequired,
        updateLoadStatus: PropTypes.func.isRequired,
        brandUrl: PropTypes.string.isRequired
    };

    containerFunctions = {
        requestProductList: this.requestProductList.bind(this)
    };

    getIsLoading() {
        const { isLoading } = this.props;

        if (!navigator.onLine) {
            return false;
        }

        return isLoading;
    }

    requestProductList(options) {
        const { requestProductList, brandUrl } = this.props;

        requestProductList({
            ...options,
            args: {
                ...options.args,
                brandUrl
            }
        });
    }

    containerProps = () => ({
        isLoading: this.getIsLoading(),
        mix: { block: 'BrandProductList' },
        isPreventRequest: false
    });

    render() {
        return (
            <ModifiedProductList
              /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.props }
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandProductListContainer);
