import {
    LayerGroup, Map, Marker, TileLayer
} from 'react-leaflet';

import ContentWrapper from 'Component/ContentWrapper';
import { MapMarker } from 'Component/MapMarker/MapMarker.component';
import NoMatch from 'Route/NoMatch';
import CmsPage from 'SourceRoute/CmsPage/CmsPage.component';

import {
    STORE_COORDINATES
} from './PlazaOutlet.config';

import 'leaflet/dist/leaflet.css';

/** @namespace Scandipwa/Route/PlazaOutlet/Component/PlazaOutletComponent */
export class PlazaOutletComponent extends CmsPage {
    render() {
        const {
            isBreadcrumbsActive,
            isLoading,
            isPageLoaded
        } = this.props;

        if (!isLoading && !isPageLoaded) {
            return <NoMatch />;
        }

        return (
            <main
              block="BoutiqueStorePage"
              mods={ { isBreadcrumbsHidden: !isBreadcrumbsActive } }
            >
                <ContentWrapper block="BoutiqueStorePage" elem="Wrapper">
                    { this.renderHeading() }
                    <div block="BoutiqueStorePage" elem="Content">
                        { this.renderContent() }
                        <div block="BoutiqueStorePage" elem="Map">
                            <Map
                              zoom={ 18 }
                              maxZoom={ 18 }
                              center={ STORE_COORDINATES }
                              zoomControl={ false }
                            >
                                <LayerGroup>
                                    <TileLayer
                                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <TileLayer url="http://tiles.openseamap.org/seamark/{z}/{x}/{y}.png" />
                                </LayerGroup>
                                <Marker icon={ MapMarker } position={ STORE_COORDINATES } />
                            </Map>
                        </div>
                    </div>
                </ContentWrapper>
            </main>
        );
    }
}

export default PlazaOutletComponent;
