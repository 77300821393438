/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { updateMeta } from 'SourceStore/Meta/Meta.action';

/** @namespace ShopbyBrand/Store/BrandMeta/Dispatcher/BrandMetaDispatcher */
export class BrandMetaDispatcher {
    updateWithBrand(brand, dispatch) {
        dispatch(updateMeta(this._getBrandMeta(brand)));
    }

    _getBrandMeta(brand) {
        const {
            name,
            meta_description,
            description
        } = brand;

        return {
            description: meta_description || description,
            title: name
        };
    }
}

export default new (BrandMetaDispatcher)();
