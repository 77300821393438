/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Breadcrumbs/Breadcrumbs.container';

import Breadcrumbs from './Breadcrumbs.component';

/** @namespace Scandipwa/Component/Breadcrumbs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    device: state.ConfigReducer.device
});

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
