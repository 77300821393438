/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateBreadcrumbs } from 'SourceStore/Breadcrumbs/Breadcrumbs.action';
import { updateMeta } from 'SourceStore/Meta/Meta.action';
import { updateNoMatch } from 'SourceStore/NoMatch/NoMatch.action';

import BrandsPage from './BrandsPage.component';

/** @namespace ShopbyBrand/Route/BrandsPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isNoMatch: state.NoMatchReducer.noMatch
});

/** @namespace ShopbyBrand/Route/BrandsPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs: (breadcrumbs) => dispatch(updateBreadcrumbs(breadcrumbs)),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    updateNoMatch: (noMatch) => dispatch(updateNoMatch(noMatch))
});

/** @namespace ShopbyBrand/Route/BrandsPage/Container/BrandsPageContainer */
export class BrandsPageContainer extends PureComponent {
    static propTypes = {
        updateBreadcrumbs: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        updateNoMatch: PropTypes.func.isRequired,
        isNoMatch: PropTypes.bool.isRequired
    };

    componentDidMount() {
        const { isNoMatch, updateNoMatch } = this.props;
        if (isNoMatch) {
            updateNoMatch(false);
        }
        this._updateMeta();
        this._updateBreadcrumbs();
    }

    _updateBreadcrumbs() {
        const { updateBreadcrumbs } = this.props;

        updateBreadcrumbs([
            { name: __('Brands') },
            { url: '/', name: __('Home') }
        ]);
    }

    _updateMeta() {
        const { updateMeta } = this.props;

        updateMeta({ title: __('Brands') });
    }

    render() {
        return (
            <BrandsPage
              /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.props }
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    BrandsPageContainer
);
