// eslint-disable-next-line
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';

/** @namespace Scandipwa/Component/MyAccountSignIn/Container/MyAccountSignInContainer */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    async onSignInSuccess(fields) {
        const {
            signIn,
            showNotification,
            onSignIn,
            setLoadingState
        } = this.props;

        try {
            await signIn(fields);
            onSignIn();
        } catch ([{ message }]) {
            setLoadingState(false);

            const errorMessage = message == null ? message : `The account sign-in was incorrect or your account
                is disabled temporarily. Please wait and try again later.`;

            showNotification(
                'error',
                errorMessage
            );
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
