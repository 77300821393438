/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { showNotification } from 'SourceStore/Notification/Notification.action';
import { QueryDispatcher } from 'SourceUtil/Request';

import BrandConfigQuery from '../../query/BrandConfig.query';
import { updateBrandConfig } from './BrandConfig.action';

/** @namespace ShopbyBrand/Store/BrandConfig/Dispatcher/BrandConfigDispatcher */
export class BrandConfigDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess({ shopByBrandConfiguration }, dispatch) {
        dispatch(
            updateBrandConfig(shopByBrandConfiguration),
        );
    }

    onError(error, dispatch) {
        dispatch(showNotification('error', __('Could not fetch the brand configuration')));
        // eslint-disable-next-line no-console
        console.error(error);
    }

    prepareRequest() {
        return BrandConfigQuery.getQuery();
    }
}

export default new (BrandConfigDispatcher)();
