/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

/**
 * The Amasty GDPR consent type that is shown during registration
 * @type {string}
 */
export const AREA_REGISTRATION = 'registration';

/**
 * The Amasty GDPR consent type that is shown at checkout
 * @type {string}
 */
export const AREA_CHECKOUT = 'checkout';

/**
 * The Amasty GDPR consent type that is shown on the contact us page
 * @type {string}
 */
export const AREA_CONTACT_US = 'contactus';

/**
 * The Amasty GDPR consent type that is shown when subscribing to the newsletter
 * @type {string}
 */
export const AREA_SUBSCRIPTION = 'subscription';

/**
 * All Amasty GDPR consent areas
 * @type {string[]}
 */
export const AREAS = [
    AREA_REGISTRATION,
    AREA_CHECKOUT,
    AREA_CONTACT_US,
    AREA_SUBSCRIPTION
];

export const ALL = 'ALL';

/**
 * Formats an object representing the selected consents into a shape accepted by the GraphQl [consentUpdateInput]
 * input - an array of objects
 * @param consents
 * @returns {{code: *, accept: *}[]}
 */
export const formatConsents = consents => Object.entries(consents)
    .map(([code, accept]) => ({
        code,
        accept
    }));
