/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import { Field } from 'Util/Query';
import { AREAS } from '../util/Privacy';

export class PrivacyInformationQuery {
    getQuery() {
        return new Field('getPrivacyInformation')
            .addFieldList([
                this._getConsentsField(),
                this._getPrivacyPolicyField()
            ]);
    }

    getSaveConsentSettingsMutation(options, area = false) {
        const mutation = new Field('updateConsents')
            .addArgument('consentUpdates', '[consentUpdateInput]!', options);

        if (area) {
            mutation.addArgument('area', 'String', area);
        }

        return mutation;
    }

    _getConsentsField() {
        return new Field('consents')
            .addFieldList(this._getConsentSubfields());
    }

    _getConsentSubfields() {
        return AREAS.map(area => this._getConsentFieldForArea(area));
    }

    _getConsentFieldForArea(area) {
        return new Field(area)
            .addFieldList([
                'name',
                'code',
                'id',
                'isRequired',
                'isAgreed',
                'text'
            ]);
    }

    _getPrivacyPolicyField() {
        return new Field('privacyPolicy');
    }
}

export default new (middleware(PrivacyInformationQuery, 'Query/PrivacyInformation'))();
