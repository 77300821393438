/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { Field } from 'SourceUtil/Query';

/** @namespace ShopbyBrand/Query/BrandConfig/Query/BrandConfigQuery */
export class BrandConfigQuery {
    getQuery() {
        return new Field('shopByBrandConfiguration')
            .addFieldList(this.getConfigurationFields());
    }

    getConfigurationFields() {
        return [
            'display_brand_description',
            'display_brand_info'
        ];
    }
}

export default new (BrandConfigQuery)();
