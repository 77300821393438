import {
    IMAGE_NOT_FOUND,
    IMAGE_NOT_SPECIFIED
} from 'Component/Image/Image.config';
import {
    Logo as SourceLogo
} from 'SourceComponent/Logo/Logo.component';

/** @namespace Scandipwa/Component/Logo/Component/LogoComponent */
export class LogoComponent extends SourceLogo {
    renderImage() {
        const { imageStatus } = this.state;
        const { src } = this.props;

        if (!src) {
            return this.renderPlaceholderLogo();
        }

        switch (imageStatus) {
        case IMAGE_NOT_FOUND:
        case IMAGE_NOT_SPECIFIED:
            return this.renderPlaceholderLogo();
        default:
            return super.renderImage();
        }
    }
}

export default LogoComponent;
