/* eslint-disable no-magic-numbers */
import L from 'leaflet';

import marker from '../../public/assets/images/icons/map-marker.png';

export const MapMarker = L.icon({
    iconUrl: marker,
    iconSize: [32, 32],
    iconAnchor: [32, 32],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
});
