/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { isCrawler, isSSR, toggleScroll } from 'Util/Browser';
import { fetchMutation } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';

import GdprCookieQuery from '../../query/GdprCookie.query';
import { updateCookiePopupStatus } from '../../store/Cookies';
import GdprCookiePopup from './GdprCookiePopup.component';
import { GDPR_COOKIES_POPUP_ID } from './GdprCookiePopup.config';

/** @namespace GdprCookie/Component/GdprCookiePopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeOverlay: state.OverlayReducer.activeOverlay,
    isCookieBarActive: state?.ConfigReducer?.gdpr_cookie_bar,
    isLoading: state.CookiesReducer.isLoading
});

/** @namespace GdprCookie/Component/GdprCookiePopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    toggleOverlayByKey: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    updateCookiePopupStatus: (status) => dispatch(updateCookiePopupStatus(status)),
    showPopup: (payload) => dispatch(showPopup(GDPR_COOKIES_POPUP_ID, payload))
});

/** @namespace GdprCookie/Component/GdprCookiePopup/Container/GdprCookiePopupContainer */
export class GdprCookiePopupContainer extends DataContainer {
    static propTypes = {
        toggleOverlayByKey: PropTypes.func.isRequired,
        activeOverlay: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired,
        isCookieBarActive: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        updateCookiePopupStatus: PropTypes.func.isRequired
    };

    state = {
        isLoading: true,
        checkedGroups: [],
        isCookieSet: this.handleGetCookie('amcookie_allowed') || false,
        cookieSettings: null,
        isStatic: true
    };

    containerFunctions = {
        handleCheckedGroups: this.handleCheckedGroups.bind(this),
        handleAcceptAllGroups: this.handleAcceptAllGroups.bind(this),
        handleDeclineCookies: this.handleDeclineCookies.bind(this),
        handleAcceptCookies: this.handleAcceptCookies.bind(this)
    };

    componentDidMount() {
        const { showPopup } = this.props;
        const {
            isCookieSet
        } = this.state;

        if (!isCookieSet) {
            this._getCookieSettings();
        }

        showPopup();
    }

    componentDidUpdate() {
        const { isCookieBarActive } = this.props;
        const {
            isLoading,
            isCookieSet
        } = this.state;

        if (isLoading && isCookieBarActive && !isCookieSet) {
            this._getCookieGroups();
            document.documentElement.classList.add('scrollDisabled');
        }
    }

    handleCheckedGroups(groupId, isChecked) {
        const { checkedGroups } = this.state;

        if (isChecked) {
            this.setState({ checkedGroups: [...checkedGroups, groupId] });
        } else {
            this.setState({ checkedGroups: checkedGroups.filter((checkedGroupId) => checkedGroupId !== groupId) });
        }
    }

    async handleAcceptCookies() {
        const {
            toggleOverlayByKey,
            updateCookiePopupStatus,
            showNotification,
            isLoading
        } = this.props;
        const { checkedGroups } = this.state;

        if (isLoading) {
            return;
        }

        updateCookiePopupStatus(true);

        const request = await fetchMutation(GdprCookieQuery.setSelectedCookies(checkedGroups));

        const { setSelectedCookies: { message, result } } = request;

        if (result) {
            showNotification('success', message);
            toggleOverlayByKey(GDPR_COOKIES_POPUP_ID);
        }

        this.setState({ isStatic: false });
        updateCookiePopupStatus(false);
    }

    async handleAcceptAllGroups() {
        const {
            toggleOverlayByKey,
            showNotification,
            isLoading,
            updateCookiePopupStatus
        } = this.props;

        if (isLoading) {
            return;
        }

        updateCookiePopupStatus(true);

        await fetchMutation(GdprCookieQuery.setAllCookies())
            .then(
                /** @namespace GdprCookie/Component/GdprCookiePopup/Container/fetchMutation/then */
                ({ setAllCookies: { message, result } = {} }) => {
                    if (result) {
                        showNotification('success', message);
                        toggleOverlayByKey(GDPR_COOKIES_POPUP_ID);
                    }
                    this.setState({ isStatic: false });
                    updateCookiePopupStatus(false);
                }
            );
    }

    async handleDeclineCookies() {
        const { toggleOverlayByKey, updateCookiePopupStatus, isLoading } = this.props;
        const { cookieGroups } = this.state;

        if (isLoading) {
            return;
        }

        const essentialCookies = cookieGroups.reduce((acc, cookieGroup) => {
            const { isEssential, groupId } = cookieGroup;

            if (!isEssential) {
                return acc;
            }

            return [...acc, groupId];
        }, []);

        updateCookiePopupStatus(true);

        await fetchMutation(GdprCookieQuery.setSelectedCookies(essentialCookies))
            .then(
                /** @namespace GdprCookie/Component/GdprCookiePopup/Container/fetchMutation/then */
                () => {
                    toggleOverlayByKey(GDPR_COOKIES_POPUP_ID);
                    updateCookiePopupStatus(false);
                    this.setState({ isStatic: false });
                }
            );
    }

    // eslint-disable-next-line consistent-return
    handleGetCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    }

    _getCookieGroups() {
        this.fetchData(
            [GdprCookieQuery.getCookieGroups()],
            ({ cookieGroups }) => {
                this.setState({ isLoading: false });
                this.setState({ cookieGroups });
            }
        );
    }

    _getCookieSettings() {
        this.fetchData(
            [GdprCookieQuery.getCookieSettings()],
            ({ gdprCookieSettings }) => {
                this.setState({ cookieSettings: gdprCookieSettings });
            }
        );
    }

    render() {
        const {
            cookieGroups, isCookieSet, isLoading, isStatic, cookieSettings
        } = this.state;
        const { isCookieBarActive } = this.props;

        // disable popup for crawlers so page content is not blocked and page is scrollable
        if (isCrawler() || isSSR()) {
            toggleScroll(true);
            return null;
        }

        return (
            <GdprCookiePopup
              cookieGroups={ cookieGroups }
              isCookieSet={ isCookieSet }
              isLoading={ isLoading }
              isStatic={ isStatic }
              isCookieBarActive={ isCookieBarActive }
              cookieSettings={ cookieSettings }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GdprCookiePopupContainer);
