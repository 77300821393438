/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { BrandReducer } from '../store/Brand';
import { BrandConfigReducer } from '../store/BrandConfig';
import { BrandListReducer } from '../store/BrandList';

class GetReducersPlugin {
    getReducers = (args, callback, instance) => ({
        BrandReducer,
        BrandConfigReducer,
        BrandListReducer,
        ...callback.apply(instance, args)
    });
}

const { getReducers } = new GetReducersPlugin();

export default {
    'Store/Index/getReducers': {
        function: getReducers
    }
};
