import { connect } from 'react-redux';

import {
    mapStateToProps,
    MyAccountAddressPopupContainer as SourceMyAccountAddressPopupContainer
} from 'SourceComponent/MyAccountAddressPopup/MyAccountAddressPopup.container';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/MyAccountAddressPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showErrorNotification: (error) => dispatch(showNotification('error', error)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    updateCustomerDetails: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE))
});

/** @namespace Scandipwa/Component/MyAccountAddressPopup/Container/MyAccountAddressPopupContainer */
export class MyAccountAddressPopupContainer extends SourceMyAccountAddressPopupContainer {
    handleAfterAction = () => {
        const {
            hideActiveOverlay,
            updateCustomerDetails,
            goToPreviousHeaderState
        } = this.props;

        updateCustomerDetails();
        this.setState({ isLoading: false });
        hideActiveOverlay();
        goToPreviousHeaderState();
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAddressPopupContainer);
