/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { PureComponent } from 'react';

import ContentWrapper from 'SourceComponent/ContentWrapper';

import BrandList from '../../component/BrandList/BrandList.container';

/** @namespace ShopbyBrand/Route/BrandsPage/Component/BrandsPageComponent */
export class BrandsPageComponent extends PureComponent {
    render() {
        return (
            <main block="BrandsPage" aria-label="Brands Page">
                <ContentWrapper
                  wrapperMix={ { block: 'BrandsPage', elem: 'Wrapper' } }
                  label="Brands"
                >
                    <BrandList isRenderFull={ false } />
                </ContentWrapper>
            </main>
        );
    }
}

export default BrandsPageComponent;
