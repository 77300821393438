/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { formatBrandList } from '../../util/brand';
import { SET_BRAND_LIST_LOADING, UPDATE_BRAND_LIST } from './BrandList.action';

export const initialState = {
    brandList: [],
    isLoading: true
};

/** @namespace ShopbyBrand/Store/BrandList/Reducer/BrandListReducer */
export const BrandListReducer = (state = initialState, action) => {
    const {
        brandList,
        type,
        isLoading
    } = action;

    switch (type) {
    case UPDATE_BRAND_LIST:
        return {
            ...state,
            isLoading: false,
            brandList: formatBrandList(brandList) || []
        };
    case SET_BRAND_LIST_LOADING:
        return {
            ...state,
            isLoading
        };
    default:
        return state;
    }
};

export default BrandListReducer;
