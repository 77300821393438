/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'SourceComponent/Image';
import Link from 'SourceComponent/Link';

import { brandListType } from '../../type/Brand';
import { BRAND_URL } from '../../util/brand';

import './BrandList.style.scss';

/** @namespace ShopbyBrand/Component/BrandList/Component/BrandListComponent */
export class BrandListComponent extends PureComponent {
    static propTypes = {
        featuredBrands: brandListType.isRequired,
        brandList: brandListType.isRequired,
        isRenderFull: PropTypes.bool
    };

    static defaultProps = {
        isRenderFull: true
    };

    renderContentFull(brandList, featuredBrands) {
        return (
            <>
                { this.renderBrands(featuredBrands) }
                { this.renderBrands(brandList) }
            </>
        );
    }

    renderContentReduced(featuredBrands) {
        return (
            <>
                { this.renderBrands(featuredBrands) }
            </>
        );
    }

    renderContent() {
        const { featuredBrands, brandList, isRenderFull } = this.props;
        return isRenderFull
            ? this.renderContentFull(brandList, featuredBrands)
            : this.renderContentReduced(featuredBrands);
    }

    renderBrands(brands) {
        return (
            <ul block="BrandList" elem="List">
                { brands.map((brand) => this.renderBrand(brand)) }
            </ul>
        );
    }

    renderBrand(brand) {
        const { url_key, logoUrl } = brand;

        return (
            <li block="BrandList" elem="Brand" key={ url_key }>
                <Link
                  to={ `${BRAND_URL}/${url_key}` }
                  mix={ { block: 'BrandList', elem: 'Link' } }
                >
                    { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                    { logoUrl ? this.renderLogo(brand) : this.renderName(brand) }
                </Link>
            </li>
        );
    }

    renderLogo(brand) {
        const { name, logoUrl } = brand;

        return (
            <Image
              src={ logoUrl }
              alt={ __('%s brand logo', name) }
              mix={ { block: 'BrandList', elem: 'Image' } }
              ratio="custom"
            />
        );
    }

    renderName(brand) {
        const { name } = brand;

        return (
            <h4 block="BrandList" elem="Name">
                { name }
            </h4>
        );
    }

    render() {
        const { featuredBrands, brandList, isRenderFull } = this.props;
        if (!featuredBrands || featuredBrands.length === 0) {
            if (isRenderFull) {
                if (!brandList || brandList.length === 0) {
                    return null;
                }
            } else {
                return null;
            }
        }

        return (
            <section block="BrandList">
                { this.renderContent() }
            </section>
        );
    }
}

export default BrandListComponent;
