import {
    TierPrices as SourceTierPrices
} from 'SourceComponent/TierPrices/TierPrices.component';

/** @namespace Scandipwa/Component/TierPrices/Component/TierPricesComponent */
export class TierPricesComponent extends SourceTierPrices {
    render() {
        const { product, product: { price_tiers = [] } } = this.props;

        if (!price_tiers || Object.keys(product).length <= 0 || !price_tiers.length) {
            return null;
        }

        return (
            <div block="TierPrices">
                { this.renderTierPrice() }
            </div>
        );
    }
}

export default TierPricesComponent;
