/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Aivars Arbidans <info@scandiweb.com>
 */

import { Field } from 'Util/Query';

export class PrivacySettingsQuery {
    getCustomerPrivacyData(password) {
        return new Field('getCustomerData')
            .addArgument('customerPassword', 'String!', password)
            .addFieldList(this._getCustomerDataResponse());
    }

    deleteCustomer(password) {
        return new Field('deleteCustomer')
            .addArgument('customerPassword', 'String!', password)
            .addFieldList(this._getCustomerResponse());
    }

    anonymiseCustomerData(password) {
        return new Field('anonymiseCustomerData')
            .addArgument('customerPassword', 'String!', password)
            .addFieldList(this._getCustomerResponse());
    }

    _getCustomerResponse() {
        return [
            'result',
            'message'
        ];
    }

    _getCustomerDataResponse() {
        return [
            this._getCustomerDataResultFields(),
            'message'
        ];
    }

    _getCustomerDataResultFields() {
        return new Field('result')
            .addFieldList([
                'key',
                'value'
            ]);
    }
}

export default new (middleware(PrivacySettingsQuery, 'Query/PrivacySettings'))();
