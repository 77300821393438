/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';

import './SearchIcon.style';

/** @namespace Component/SearchIcon/Component */
export class SearchIcon extends PureComponent {
    render() {
        return (
            <svg
              block="SearchIcon"
              width="28" 
              height="28" 
              viewBox="0 0 28 28" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
            >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 13.1098C6 9.18316 9.18316 6 13.1098 6C17.0364 6 20.2196 9.18316 20.2196 13.1098C20.2196 14.8196 19.6164 16.3877 18.611 17.6142L18.4242 17.842L22 21.4179L21.4743 21.9436L17.9014 18.3708L17.6731 18.5621C16.4382 19.5969 14.8471 20.2196 13.1098 20.2196C9.18316 20.2196 6 17.0364 6 13.1098ZM13.1098 6.74351C9.59379 6.74351 6.74351 9.59379 6.74351 13.1098C6.74351 16.6258 9.59379 19.4761 13.1098 19.4761C16.6258 19.4761 19.4761 16.6258 19.4761 13.1098C19.4761 9.59379 16.6258 6.74351 13.1098 6.74351Z" fill="black"/>
            </svg>

        );
    }
}

export default SearchIcon;