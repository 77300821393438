/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductListContainer as SourceProductListContainer
} from 'SourceComponent/ProductList/ProductList.container';

/** @namespace ShopbyBrand/Component/ModifiedProductList/Container/ModifiedProductListContainer */
export class ModifiedProductListContainer extends SourceProductListContainer {
    state = {
        pagesCount: 1,
        isPageRequested: false
    };

    componentDidMount() {
        const { pages, isBrandLoading } = this.props;
        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            this.setState({ pagesCount: pagesLength });
        }

        // Is true when category is changed. This check prevents making new requests when navigating back to PLP from PDP
        if (!isBrandLoading) {
            this.requestPage(this._getPageFromUrl());
            return;
        }

        this.setState({
            isPageRequested: false
        });
    }

    componentDidUpdate(prevProps) {
        const {
            sort, search, filter, brand: { url_key }, isBrandLoading
        } = this.props;
        const {
            sort: prevSort, search: prevSearch, filter: prevFilter, brand: { url_key: prevBrandUrl }
        } = prevProps;

        const { pages } = this.props;
        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ pagesCount: pagesLength });
        }

        if (!isBrandLoading) {
            if (
                search !== prevSearch
                || JSON.stringify(sort) !== JSON.stringify(prevSort)
                || JSON.stringify(filter) !== JSON.stringify(prevFilter)
                || url_key !== prevBrandUrl
            ) {
                this.requestPage(this._getPageFromUrl());
                return;
            }
        }

        // Trigger componentDidUpdate in case the page wasn't requested due to brand not yet being loaded in reducer
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
            isPageRequested: false
        });
    }

    requestPage = (currentPage = 1, isNext = false) => {
        const {
            sort,
            search,
            filter,
            pageSize,
            requestProductList,
            requestProductListInfo,
            noAttributes,
            noVariants,
            isWidget,
            filter: {
                customFilters
            },
            brand: {
                attribute_code: attributeCode,
                option_id: brandId
            }
        } = this.props;

        const options = {
            isNext,
            noAttributes,
            noVariants,
            args: {
                sort,
                filter,
                search,
                pageSize,
                currentPage
            }
        };

        const infoOptions = {
            args: {
                filter: {
                    ...filter,
                    customFilters: {
                        ...customFilters,
                        [attributeCode]: brandId
                    }
                },
                search
            }
        };

        requestProductList(options);

        if (!isWidget) {
            requestProductListInfo(infoOptions);
        }
    };
}

export default connect(sourceMapStateToProps, sourceMapDispatchToProps)(ModifiedProductListContainer);
