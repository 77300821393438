/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';

import './CloseIcon.style';

/** @namespace Scandipwa/Component/CloseIcon/Component */
export class CloseIcon extends PureComponent {
    render() {
        return (
            <svg
              block="CloseIcon"
              width="16" 
              height="16" 
              viewBox="0 0 17 16" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
            >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.34945 7.3737L14.2353 1.48789L14.8984 2.15102L9.01257 8.03683L14.8247 13.8489L14.1615 14.5121L8.34945 8.69995L2.46364 14.5858L1.80051 13.9226L7.68632 8.03682L1.72681 2.07731L2.38994 1.41418L8.34945 7.3737Z" fill="black"/>
            </svg>
        );
    }
}

export default CloseIcon;
