import {
    NavigationTabs as BaseNavigationTabs
} from 'SourceComponent/NavigationTabs/NavigationTabs.component';

import './NavigationTabs.extend.style';

/** @namespace Scandipwa/Component/NavigationTabs/Component/NavigationTabsComponent */
export class NavigationTabsComponent extends BaseNavigationTabs {
    renderHomeButton(isActive = false) {
        const { onHomeButtonClick } = this.props;

        return (
            <button
              key="home"
              block="NavigationTabs"
              elem="Button"
              aria-label="Home"
              onClick={ onHomeButtonClick }
            >
                <div
                  block="Header"
                  elem="Button"
                  mix={ { block: 'NavigationTabs', elem: 'Icon-Home', mods: { isActive } } }
                  mods={ { type: 'home', isVisible: true } }
                />
            </button>
        );
    }

    renderMenuButton(isActive = false) {
        const { onMenuButtonClick } = this.props;

        return (
            <button
              key="menu"
              block="NavigationTabs"
              elem="Button"
              aria-label="Go to menu and search"
              onClick={ onMenuButtonClick }
            >
                <div
                  block="Header"
                  elem="Button"
                  mix={ { block: 'NavigationTabs', elem: 'Icon-Menu', mods: { isActive } } }
                  mods={ { isVisible: true, type: 'menu' } }
                />
            </button>
        );
    }

    renderAccountButton(isActive = false) {
        const { onMyAccountButtonClick } = this.props;

        return (
            <button
              key="account"
              block="NavigationTabs"
              elem="Button"
              onClick={ onMyAccountButtonClick }
              aria-label="Open my account"
            >
                <div
                  block="Header"
                  elem="Button"
                  mix={ { block: 'NavigationTabs', elem: 'Icon-Account', mods: { isActive } } }
                  mods={ { isVisible: true, type: 'account' } }
                />
            </button>
        );
    }

    renderMinicartButton(isActive = false) {
        const { onMinicartButtonClick } = this.props;

        return (
            <button
              key="minicart"
              block="NavigationTabs"
              elem="Button"
              onClick={ onMinicartButtonClick }
              aria-label="Minicart"
            >
                <div
                  block="Header"
                  elem="Button"
                  mix={ { block: 'NavigationTabs', elem: 'Icon-Minicart', mods: { isActive } } }
                  mods={ { isVisible: true, type: 'minicart' } }
                >
                    { this.renderMinicartItemsQty() }
                </div>
            </button>
        );
    }
}

export default NavigationTabsComponent;
