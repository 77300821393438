/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { brandListType } from '../../type/Brand';
import BrandList from './BrandList.component';

/** @namespace ShopbyBrand/Component/BrandList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    brandList: state.BrandListReducer.brandList,
    isLoading: state.BrandListReducer.isLoading
});

/** @namespace ShopbyBrand/Component/BrandList/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

/** @namespace ShopbyBrand/Component/BrandList/Container/BrandListContainer */
export class BrandListContainer extends PureComponent {
    static propTypes = {
        brandList: brandListType.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    containerProps = () => ({
        featuredBrands: this.getFeaturedBrands()
    });

    getFeaturedBrands() {
        const { brandList } = this.props;
        return brandList.filter((brand) => brand.is_featured);
    }

    render() {
        const { brandList } = this.props;

        return (
            <BrandList
              brandList={ brandList }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandListContainer);
