/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

// import { GERMAN_STORE_SWITCHER_POPUP_ID } from 'Component/GermanStoreSwitcherPopup/GermanStoreSwitcherPopup.component';
import Html from 'Component/Html';
import Popup from 'Component/Popup';

import { cookieInfoType, cookieSettingsType } from '../../type/GdprCookie';
import GdprCookieGroupItem from '../GdprCookieGroupItem';
import { GDPR_COOKIES_POPUP_ID } from './GdprCookiePopup.config';

import './GdprCookiePopup.style';

/** @namespace GdprCookie/Component/GdprCookiePopup/Component/GdprCookiePopupComponent */
export class GdprCookiePopupComponent extends PureComponent {
    static propTypes = {
        cookieGroups: PropTypes.arrayOf(cookieInfoType).isRequired,
        handleCheckedGroups: PropTypes.func.isRequired,
        handleAcceptAllGroups: PropTypes.func.isRequired,
        handleAcceptCookies: PropTypes.func.isRequired,
        handleDeclineCookies: PropTypes.func.isRequired,
        cookieSettings: PropTypes.arrayOf(cookieSettingsType).isRequired,
        isCookieSet: PropTypes.bool.isRequired,
        isStatic: PropTypes.bool.isRequired,
        isCookieBarActive: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    renderCookieGroup = (cookieGroup) => {
        const { handleCheckedGroups } = this.props;
        const {
            groupId,
            isEssential,
            name,
            description,
            cookies
        } = cookieGroup;

        return (
            <GdprCookieGroupItem
              key={ `${groupId}-${name}` }
              groupId={ groupId }
              isEssential={ isEssential }
              name={ name }
              description={ description }
              cookies={ cookies }
              handleCheckedGroups={ handleCheckedGroups }
            />
        );
    };

    renderCookieGroups() {
        const { cookieGroups } = this.props;

        if (Object.keys(cookieGroups).length === 0) {
            return null;
        }

        return cookieGroups.reduce(
            (acc, e) => [...acc, this.renderCookieGroup(e)],
            []
        );
    }

    renderButtons() {
        const {
            handleAcceptAllGroups,
            handleDeclineCookies,
            handleAcceptCookies
        } = this.props;

        return (
            <div
              block="GdprCookiesPopup"
              elem="Buttons"
            >
                <button
                  block="GdprCookiesPopup"
                  elem="Allow"
                  type="button"
                  mix={ { block: 'Button' } }
                  onClick={ handleAcceptCookies }
                >
                    { __('Accept Cookies') }
                </button>
                <button
                  block="GdprCookiesPopup"
                  elem="Decline"
                  type="button"
                  mix={ { block: 'Button', mods: { isHollow: true } } }
                  onClick={ handleDeclineCookies }
                >
                    { __('Decline Cookies') }
                </button>
                <button
                  block="GdprCookiesPopup"
                  elem="AllowAll"
                  type="button"
                  mix={ { block: 'Button', mods: { isHollow: true } } }
                  onClick={ handleAcceptAllGroups }
                >
                    { __('Allow All Cookies') }
                </button>
            </div>
        );
    }

    renderActions() {
        const { cookieSettings: { gdpr_cookie_bar_text } } = this.props;

        return (
            <div
              block="GdprCookiesPopup"
              elem="Actions"
            >
                <p
                  block="GdprCookiesPopup"
                  elem="Description"
                >
                    <Html content={ gdpr_cookie_bar_text } />
                </p>
                { this.renderButtons() }
            </div>
        );
    }

    renderContent() {
        const {
            isLoading,
            cookieGroups,
            cookieSettings
        } = this.props;

        if (isLoading || !cookieGroups || !cookieSettings) {
            return null;
        }

        return (
            <div
              block="GdprCookiesPopup"
              elem="Wrapper"
            >
                <div
                  block="GdprCookiesPopup"
                  elem="Groups"
                >
                    { this.renderCookieGroups() }
                </div>
                { this.renderActions() }
            </div>
        );
    }

    render() {
        const {
            isCookieSet, isStatic, isCookieBarActive
        } = this.props;

        if (isCookieSet || !isCookieBarActive) {
            return null;
        }

        return (
            <Popup
              id={ GDPR_COOKIES_POPUP_ID }
              clickOutside={ false }
              isStatic={ isStatic }
              mix={ { block: 'GdprCookiesPopup' } }
            >
                { this.renderContent() }
            </Popup>
        );
    }
}

export default GdprCookiePopupComponent;
