/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { ProductListQuery } from 'SourceQuery/ProductList.query';
import { Field } from 'SourceUtil/Query';

/** @namespace ShopbyBrand/Query/Brand/Query/BrandQuery */
export class BrandQuery {
    getListQuery() {
        return new Field('brand')
            .addFieldList(this.getBrandFields(false));
    }

    getQueryForProduct(productId) {
        if (typeof productId !== 'number') {
            throw new Error('Must specify a number for the product ID');
        }

        return new Field('productBrand')
            .addArgument('id', 'Int!', productId)
            .addFieldList(this.getProductBrandFields());
    }

    getQueryForBrandUrl(brandUrl) {
        if (typeof brandUrl !== 'string') {
            throw new Error('Must specify a brand URL (string)');
        }

        return new Field('brand')
            .addArgument('url_key', 'String', brandUrl)
            .addFieldList(this.getBrandFields(true));
    }

    getBrandFields(withDetails) {
        return [
            'attribute_code',
            'option_id',
            'name',
            'url_key',
            'logo',
            'is_featured',
            ...withDetails ? this.getBrandDetailFields() : []
        ];
    }

    getBrandDetailFields() {
        return [
            'meta_description',
            'description'
        ];
    }

    getProductBrandFields() {
        return [
            new Field('brand').addFieldList(
                this.getBrandFields(true),
            ),
            new Field('additionalProducts').addFieldList(
                this.getProductFields(),
            )
        ];
    }

    getProductFields() {
        // accessing a field and method that was not meant to be exposed: a bit hacky, but the only way to
        // reuse code for querying a ProductInterface
        const PLQ = new ProductListQuery();
        PLQ.options = {
            isSingleProduct: false,
            noAttributes: true
        };

        return PLQ._getProductInterfaceFields(false, true);
    }
}

export default new (BrandQuery)();
