/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ListIcon.style';

/** @namespace Component/ListIcon/Component */
export class ListIcon extends PureComponent {
    static propTypes = {
        isActive: PropTypes.bool
    };

    static defaultProps = {
        isActive: false
    };

    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="ListIcon"
              mods={ { isActive } }
              width="16" 
              height="16" 
              viewBox="0 0 16 16" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
            >
                <line y1="1.65" x2="16" y2="1.65" stroke="black" stroke-width="0.7"/>
                <line y1="7.65" x2="16" y2="7.65" stroke="black" stroke-width="0.7"/>
                <line y1="13.65" x2="16" y2="13.65" stroke="black" stroke-width="0.7"/>            
            </svg>
        );
    }
}

export default ListIcon;