/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ClickOutside from 'Component/ClickOutside';
import Popup from 'Component/Popup';

import { cookieInfoType } from '../../type/GdprCookie';
import { GDPR_COOKIE_GROUP_POPUP_ID } from './GdprCookieGroupPopup.config';

import './GdprCookieGroupPopup.style.scss';

/** @namespace GdprCookie/Component/GdprCookieGroupPopup/Component/GdprCookieGroupPopupComponent */
export class GdprCookieGroupPopupComponent extends PureComponent {
    static propTypes = {
        cookieInfo: cookieInfoType.isRequired,
        handleClosePopup: PropTypes.func.isRequired,
        isCookieBarActive: PropTypes.bool.isRequired
    };

    renderName() {
        const { cookieInfo: { name } } = this.props;

        return (
            <h4
              block="GdprCookieGroupPopup"
              elem="Heading"
            >
                { name }
            </h4>
        );
    }

    renderDescription() {
        const { cookieInfo: { description } } = this.props;

        return (
            <p
              block="GdprCookieGroupPopup"
              elem="Description"
            >
                { description }
            </p>
        );
    }

    renderCookiesTableHeadings() {
        return (
            <tr>
                <th>{ __('Cookie Name') }</th>
                <th>{ __('Cookie Description') }</th>
                <th>{ __('Cookie Lifetime') }</th>
            </tr>
        );
    }

    renderCookiesRows() {
        const { cookieInfo: { cookies = {} } } = this.props;

        if (Object.keys(cookies).length === 0) {
            return null;
        }

        return cookies.reduce(
            (acc, e) => [...acc, this.renderCookie(e)],
            []
        );
    }

    renderCookie(cookie) {
        const { name, description, lifetime } = cookie;

        return (
            <tr
              block="GdprCookieGroupPopup"
              elem="Row"
              key={ name }
            >
                <th>{ name }</th>
                <th>{ description }</th>
                <th>{ lifetime }</th>
            </tr>
        );
    }

    renderCookiesTable() {
        return (
            <table
              block="GdprCookieGroupPopup"
              elem="Table"
            >
                <thead>
                    { this.renderCookiesTableHeadings() }
                </thead>
                <tbody>
                    { this.renderCookiesRows() }
                </tbody>
            </table>
        );
    }

    renderActions() {
        const { handleClosePopup } = this.props;

        return (
            <button
              block="GdprCookieGroupPopup"
              elem="Done"
              type="button"
              mix={ { block: 'Button' } }
              onClick={ handleClosePopup }
            >
                { __('Done') }
            </button>
        );
    }

    renderGroupContent() {
        const { handleClosePopup } = this.props;

        return (
            <ClickOutside
              block="GdprCookieGroupPopup"
              elem="Wrapper"
              onClick={ handleClosePopup }
            >
                { this.renderName() }
                { this.renderDescription() }
                { this.renderCookiesTable() }
                { this.renderActions() }
            </ClickOutside>
        );
    }

    render() {
        const { isCookieBarActive } = this.props;

        if (!isCookieBarActive) {
            return null;
        }

        return (
            <Popup
              id={ GDPR_COOKIE_GROUP_POPUP_ID }
              clickOutside={ false }
              mix={ { block: 'GdprCookieGroupPopup' } }
            >
                { this.renderGroupContent() }
            </Popup>
        );
    }
}

export default GdprCookieGroupPopupComponent;
