/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'SourceComponent/Html';
import Image from 'SourceComponent/Image';
import { MixType } from 'SourceType/Common';

import { brandType } from '../../type/Brand';

import './BrandInfo.style';

/**
 * Renders the details of a brand, such as a logo and description
 * @namespace ShopbyBrand/Component/BrandInfo/Component/BrandInfoComponent */
export class BrandInfoComponent extends PureComponent {
    static propTypes = {
        brand: PropTypes.oneOfType([brandType, PropTypes.shape({})]),
        isLoading: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/boolean-prop-naming
        withDescription: PropTypes.bool.isRequired,
        mix: MixType
    };

    static defaultProps = {
        mix: {},
        brand: {}
    };

    renderLogo() {
        const {
            brand: { logoUrl } = {},
            isLoading
        } = this.props;

        if (isLoading || !logoUrl) {
            return null;
        }

        return (
            <Image
              mix={ { block: 'BrandInfo', elem: 'Logo' } }
              src={ logoUrl }
            />
        );
    }

    renderDescription() {
        const {
            brand: {
                description = ''
            } = {},
            withDescription
        } = this.props;

        if (!withDescription) {
            return null;
        }

        return (
            <div block="BrandInfo" elem="Description">
                <Html
                  isBrandInfo
                  content={ description }
                />
            </div>
        );
    }

    render() {
        const {
            mix,
            isLoading
        } = this.props;

        if (isLoading) {
            return null;
        }

        return (
            <section block="BrandInfo" mix={ mix } mods={ { isLoading } }>
                { this.renderDescription() }
            </section>
        );
    }
}

export default BrandInfoComponent;
