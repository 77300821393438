/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import { ChildrenType } from 'Type/Common';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showPopup } from 'Store/Popup/Popup.action';

import './PrivacyPolicyButton.style.scss';
import PrivacyPolicyPopup, { PRIVACY_POLICY_POPUP_ID } from '../PrivacyPolicyPopup/PrivacyPolicyPopup.component';

export const mapStateToProps = () => ({
});

export const mapDispatchToProps = dispatch => ({
    showPopup: payload => dispatch(showPopup(PRIVACY_POLICY_POPUP_ID, payload))
});

/** @namespace Component/PrivacyPolicyButton/Component */
export class PrivacyPolicyButton extends PureComponent{
    static propTypes = {
        children: ChildrenType.isRequired,
        showPopup: PropTypes.func.isRequired
    };

    render() {
        const { children } = this.props;

        return (
            <button
              block="Button"
              mods={ { likeLinkBlue: true } }
              mix={ { block: 'PrivacyPolicyButton' } }
              onClick={ e => this.onClick(e) }
            >
                { children }
            </button>
        );
    }

    onClick(e) {
        e.preventDefault();
        e.stopPropagation();

        const { showPopup, privacyPolicy } = this.props;

        /* super-ugly workaround :|
        Issue: without timeout, the popup is shown immediately, then the click is somehow captured by ClickOutside
         wrappers, which are meant to close the current popup when the user clicks outside, and the popup is
          immediately hidden.
         */
        setTimeout(() => {
            showPopup({ privacyPolicy });
        }, 42);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyButton);
