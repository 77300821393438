/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { Route } from 'react-router-dom';

import BrandPage from '../route/BrandPage';
import BrandsPage from '../route/BrandsPage';
import { BRAND_URL } from '../util/brand';

export class RouterPlugin {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    switchItems = (originalMember) => [
        ...originalMember,
        {
            component: <Route exact path={ `${BRAND_URL}` } component={ BrandsPage } />,
            position: 120
        },
        {
            component: <Route path={ `${BRAND_URL}/:brandUrl` } component={ BrandPage } />,
            position: 420
        }
    ];
}

const { switchItems } = new RouterPlugin();

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: switchItems
        }
    }
};
