/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

import { updateNoMatch } from 'SourceStore/NoMatch/NoMatch.action';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import {
    appendPage,
    updateLoadStatus,
    updateProductListItems
} from 'SourceStore/ProductList/ProductList.action';
import { QueryDispatcher } from 'SourceUtil/Request';

import BrandProductListQuery from '../../query/BrandProductList.query';

/**
 * Product List Dispatcher
 * @class ProductListDispatcher
 * @extends QueryDispatcher
 * @namespace ShopbyBrand/Store/BrandProductList/Dispatcher/BrandProductListDispatcher
 */
export class BrandProductListDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess(data, dispatch, options) {
        const {
            brandProducts: {
                items,
                total_count,
                page_info: { total_pages } = {}
            } = {}
        } = data;
        const { args: { currentPage }, args, isNext } = options;

        if (isNext) {
            return dispatch(appendPage(items, currentPage));
        }
        if (items) {
            return dispatch(updateProductListItems(items, currentPage, total_count, total_pages, args));
        }

        return null;
    }

    onError(error, dispatch) {
        dispatch(showNotification('error', 'Error fetching Product List!', error));
        dispatch(updateNoMatch(true));
    }

    prepareRequest(options, dispatch) {
        const { isNext } = options;

        if (!isNext) {
            dispatch(updateLoadStatus(true));
        }

        return BrandProductListQuery.getQuery(options);
    }
}

export default new (BrandProductListDispatcher)();
