/* eslint-disable react/no-unused-state */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CardElement } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import { Address, PaymentTotals, Stripe } from '../../type/Stripe';

import './InjectedStripeCheckoutForm.style';

/**
 * @class InjectedStripeCheckoutForm
 * @namespace StripePayments/Component/InjectedStripeCheckoutForm/Component/InjectedStripeCheckoutFormComponent */
export class InjectedStripeCheckoutFormComponent extends PureComponent {
    static propTypes = {
        paymentTotals: PaymentTotals.isRequired,
        billingAddress: Address.isRequired,
        stripe: Stripe.isRequired,
        showNotification: PropTypes.func.isRequired,
        onPaymentMethod: PropTypes.func.isRequired,
        setOrderButtonEnableStatus: PropTypes.func.isRequired
    };

    handleChange = (change) => {
        const { setOrderButtonEnableStatus } = this.props;
        const { error, complete } = change;

        if (typeof error === 'undefined' && complete) {
            setOrderButtonEnableStatus(true);
            return;
        }

        setOrderButtonEnableStatus(false);
    };

    render() {
        return (
            <div block="InjectedStripeCheckoutForm">
                <CardElement options={ { hidePostalCode: true } } onChange={ this.handleChange } />
            </div>
        );
    }
}

export default InjectedStripeCheckoutFormComponent;
